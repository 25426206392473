<template>
  <section class="nav">
    <ul class="g4">
      <li><img class="logo" src="@/assets/logo.webp" width="0" height="0" alt="logo" /></li>
    </ul>
    <!-- <ul>
      <li>
        <router-link to="/guinchos-de-motos">
          GUINCHOS
        </router-link>
      </li>
      <li>
        <router-link to="/oficinas-credenciadas">
          OFICINAS
        </router-link>
      </li>
      <li>
        <router-link to="/pecas">
          PEÇAS
        </router-link>
      </li>
    </ul> -->
  </section>
</template>
<style scoped lang="scss">
// Navigation Variables
$nav-height: 7vh;
$nav-background: #15161e;
$nav-font-color: #ffffff;
$link-hover-color: #2581dc;
.logo {
  max-width: 150px !important;
  width: auto !important;
  height: auto !important
}
.nav {
  background: $nav-background;
  ul{
    &:nth-child(1){
      padding-top:.5rem!important ;
    }
    &:nth-child(2){
      display: grid;
      grid-template-columns: repeat(3,1fr);
      li{
        margin:1rem !important;
      }
    }
  }
  ul li{
    color:#fff
  }
}
.g4 {
  padding: 2px 0 !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0;
  ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    div {
      background: red;
    }
    li {
      color: #f5ff25;
      &:nth-of-type(2) {
        margin: 0 4px;
      }
    }
    * {
      text-decoration: none;
      list-style: none;
      white-space: nowrap;
    }
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2px;
  }
}
</style>
