<template>
  <section>
    <Nav/>
  </section>
  <section>
    <Banner/>
  </section>
  <section class="section-router">
    <router-view/>
  </section>
  <section>
    <Footer/>
  </section>
</template>
<style lang="scss">
*{
  max-width: 100vw!important;
  list-style: none!important;
  box-sizing: border-box!important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  overflow-x:hidden!important;
  margin: 0!important;
  text-decoration: none!important;
  padding: 0!important;
}
.section-router{
  margin:4rem!important;
  @media only screen and (max-width: 600px) {
    margin:4rem 0!important;
  }
}
.logo{
      max-width:150px!important;
      width: auto!important;
      height: auto!important;
    }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  background: #e2e1e0!important;
  text-align: center;
}
</style>
<script>
import Nav from '@/components/Nav.vue'
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Nav,
    Banner,
    Footer
  }
}
</script>
