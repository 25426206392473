<template>
  <div class="floating_btn">
    <a target="_blank"
      href="https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20SOS%20Motoka,%20gostaria%20de%20um%20orçamento.">
      <div class="contact_icon">
        <img src="@/assets/whatsapp-32.svg" alt="" width="32" height="32">
      </div>
    </a>
    <p class="text_icon">Entre em contato</p>
  </div>
  <div class="pg-footer">
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-content-column">
          <div class="footer-logo">
              <img class="logo" src="@/assets/logo.webp" width="0" height="0" alt="">
          </div>
        </div>
        <div class="">
          <div>
            Se você está enfrentando dificuldades com sua motocicleta e necessita de um serviço confiável de guincho, você veio ao lugar certo. Na SOS Motoka, somos especializados em oferecer serviços de guincho especialmente projetados para motos, assegurando o transporte seguro e cuidadoso da sua motocicleta até o destino desejado.
          </div>
         <!--  <div class="footer-content-column">
            <div class="footer-menu">
              <h2 class="footer-menu-name"> Empresa</h2>
              <ul id="menu-company" class="footer-menu-list">
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/contato">Contato</router-link>
                </li>
                <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
                  <router-link to="/about">Quem somos</router-link>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/noticias">Noticias</router-link>
                </li>
              </ul>
            </div>
          </div> -->
          <!-- <div class="footer-content-column">
            <div class="footer-menu">
              <h2 class="footer-menu-name"> Links Rápidos</h2>
              <ul id="menu-quick-links" class="footer-menu-list">
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <router-link to="/guincho-para-motos">GUINCHO PARA MOTOS</router-link>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <router-link to="/oficinas-credenciadas">OFICINAS CREDENCIADAS</router-link>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/pecas-e-acessorios">PEÇAS E ACESSÓRIOS</router-link>
                </li>
              </ul>
            </div>
          </div> -->
          <!-- <div class="footer-content-column">
            <div class="footer-call-to-action">
              <h2 class="footer-call-to-action-title"> Você Nos Chama</h2>
              <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link"
                  href="tel:19993537939" target="_self"> (19) 9 9353-7939 </a></p>
            </div>
          </div> -->
        </div>
      </div>
      <div class="footer-copyright">
        <div class="footer-copyright-wrapper">
          <p class="footer-copyright-text">
            <router-link class="footer-copyright-link" to="#" target="_self"> © 2023, Todos os direitos reservados. </router-link>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<style lang="scss">
.pg-footer {
  font-family:'Roboto', sans-serif
}

.footer {
  background-color:#15161e;
  padding: 1rem 0!important;

  * {
    &:nth-child(1){
      margin-top:0!important;
      border: 0;
    }
    background-color:#15161e;
    margin-top:15px!important;
    overflow:hidden!important;
    color:#fff
  }

  a {
    padding:40px 15px 450px
  }
}

.footer-wave-svg {
  display:block;
  height:30px;
  position:relative;
  top:-5px;
  width:100%
}

.footer-wave-path {
  fill:#e2e1e0;
  stroke: none;
}

.footer-content {
  padding:40px 15px 450px;
  position:relative;

  .g3 {
    display:grid;
    grid-template-columns:repeat(3, 1fr);

    .footer-content-column {
      padding-left:15px;
      padding-right:15px;
      width:100%
    }
  }
}

.footer-logo-link {
  display:inline-block
}

.footer-menu-name {
  color:#f5ff25;
  font-size:15px;
  font-weight:900;
  letter-spacing:.1em;
  line-height:18px;
  text-transform:uppercase
}

.footer-call-to-action-title {
  color:#f5ff25;
  font-size:14px;
  font-weight:900;
  letter-spacing:.1em;
  line-height:18px;
  text-transform:uppercase
}

.hidden-link-text {
  position:absolute;
  clip:rect(1px 1px 1px 1px);
  clip:rect(1px, 1px, 1px, 1px);
  -webkit-clip-path:inset(0px 0px 99.9% 99.9%);
  clip-path:inset(0px 0px 99.9% 99.9%);
  height:1px;
  width:1px;
  top:50%
}

.footer-copyright {
  padding:15px 30px;
  text-align:center
}

.footer-copyright-text {
  font-size:13px;
  font-weight:400;
  line-height:18px
}
.floating_btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}
.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  // animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87;
  /* -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1); */
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;

  img {
    margin: 0 !important;
  }
}

.text_icon {
  margin-top: 8px;

  font-size: 13px;
}
</style>
