<template>
<ul>
  <li>Pane seca (auxílio combustível)</li>
  <li>Carro de apoio (eventos)</li>
  <li>Auxiliar de partida (carga de bateria)</li>
  <li>Serviço de Leva e traz (Vistoria, oficina, lava rápido, viagens, etc)</li>
  <li>Transporte para motos (Oficinas, concessionárias, estacionamentos, autódromos, leilão, eventos, etc)</li>
</ul>
</template>
<style lang="scss" scoped>
*{
  text-align: left;
  margin: 1rem 0!important;
}
li{
  @media only screen and (max-width: 600px) {
    padding: 0 .5rem!important;
  }
}
</style>
