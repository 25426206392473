<template>
  <div class="partners-top-banner">
    <div class="top-banner-container company-top-banner-container w-container">
    </div>
    <h1 class="white-text-align-left e">GUINCHO DE MOTO</h1>
      <h5 class="white-text-align-left">ATENDIMENTO 24 HORAS<br></h5>
    <a href="https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20SOS%20Motoka,%20gostaria%20de%20um%20orçamento."
      target="_blank" class="btn-banner" rel="noopener noreferrer"><span>SOLICITE UM GUINCHO AGORA</span></a>

  </div>
</template>
<style lang="scss" scoped>
*{
  overflow: hidden;
  padding: 3rem 0!important;
}
.btn-banner {
  position: relative;
  margin: 1rem 5rem !important;
  background: #fff000;
  box-shadow: 0 0 5px #ffffffcf, 0 0 10px #ffffffcf, 0 0 10px #fff000, 0 0 20px #fff000, 0 0 30px #fff000, 0 0 40px #fff000, 0 0 50px #fff000;

  text-shadow: 0 0 1px #ffffffcf, 0 0 10px #ffffffcf, 0 0 1px #33333369, 0 0 2px #33333369, 0 0 3px #33333369, 0 0 4px #33333369, 0 0 5px #33333369;

  // border-collapse: separate;
  color: #ffffffcf;
  font-weight: bold;
  opacity: 1;
  padding: 10px 30px 10px 30px !important;
  border-radius: 99px;
  width: 25%;

  @media only screen and (max-width: 600px) {
    max-width: 90vw !important;
    width: 90%;
    margin: 0 !important;
  }
}

.partners-top-banner {
  background-image: linear-gradient(rgba(3, 3, 3, .7), rgba(3, 3, 3, .7)), url('~@/assets/banner-01.webp');
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: 100%, cover;
  padding-bottom: 6rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.e {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 10px #fff000, 0 0 20px #fff000, 0 0 30px #fff000, 0 0 40px #fff000, 0 0 50px #fff000;
  letter-spacing: 0.05em;
  line-height: 100%;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #333333a5;
    font-size: 5em;
    word-break: normal;
    @media only screen and (max-width: 600px) {
    font-size: 4rem;
}
}

.white-text-align-left {
min-width: 100%!important;
min-height: 100%!important;
  color: transparent;
}

h1 {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  line-height: 90px;

}

h1,
h5 {
  font-size: 38px;
  line-height: 44px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  strong {
    display: block;
    width: 100%;
    height: 100%
  }
  font-weight: bold;
}

h5 {
  color: #fff !important
}
</style>
